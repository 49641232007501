import moment from 'moment'
import store from '@/store/index'


export function formatNumberWithCurrency(number: number, currency?: string) {
    let currencyToShow = "EUR";
    if (currency !== undefined) currencyToShow = currency;
    if (store.getters["user/getLanguage"] !== 'en' ) {
        return new Intl.NumberFormat("es-ES", {
            style: "currency",
            useGrouping: true,
            currency: currencyToShow,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        }).format(number);
    } else {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            useGrouping: true,
            currency: currencyToShow,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        }).format(number);
    }
    /*if (store.getters["user/getLanguage"] === 'ru') {
        return new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: currencyToShow,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        }).format(number);
    }
    */
}

export function formatNumber (number: number) {
    return number.toFixed(2)
}

export function validatePhone(phone:string){
    const regex = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/
    return  regex.test(phone);
  }
  
export function formatDate(date: string | null) {
  if (date && date.length > 0) return moment(date).format("DD/MM/YYYY")
  return '-'
}

export function replaceDefaultScopes (message: string, hyperlink?: string | null) {
    let result: string = message.slice()
    result = result.replaceAll('{bold}', "<span class='font-semibold'>")
    result = result.replaceAll('{/bold}', "</span>")
    result = result.replaceAll('{gray}', "<span class='text-gray-400'>")
    result = result.replaceAll('{/gray}', "</span>")
    result = result.replaceAll('{success}', "<span class='text-secondary-color'>")
    result = result.replaceAll('{/success}', "</span>")
    const errorString: string = "<a class='underline' href='" + hyperlink + "'>"
    result = result.replaceAll('{hyperlink}', errorString)
    result = result.replaceAll('{/hyperlink}', "</a>")
    return result
}
export function validateIdentification(text: string) {
    if(text.length !== 9) return false;
    if(isNaN(parseInt(text.charAt(0)))) return isValidCIF(text)
    return isValidNIFOrNIE(text)
  }

export function isValidNIFOrNIE(dni :string) : boolean {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const str = dni.toString().toUpperCase();
    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;
    const nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');

  const letter = str.slice(- 1);
  const charIndex = parseInt(nie.slice(0, 8)) % 23;
  if (validChars.charAt(charIndex) === letter) return true;

  return false;
}


export function getSubstring(entry: string, quantity: number) {
    if (entry.length < quantity) {
        return entry;
    } else {
        return entry.substring(0, quantity) + "..";
    }
}

export function isValidCIF(cif: string) {
  const regexCIF = /^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/i; 
  return regexCIF.test(cif)
}


export function sumDigits(number: number): number{
    const stringNumber = number.toString();
    let sum = 0;
    if (stringNumber.length > 1) {
        stringNumber.split("").forEach(digit => sum += parseInt(digit))
        return sumDigits(sum)
    }
    return number
}
