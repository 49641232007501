
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import SpIcon from "@/components/ui/SpIcon.vue";
import { useStore } from "vuex";
import i18n from "@/main";
import * as Utils from "@/api/Utils";

export default defineComponent({
  name: "PinAuthentication",
  components: { SpButton, SpSelect, SpIcon },
  emits: ["successfulAuthentication"],
  props: {
    authMode: { type: String, required: true },
    sendMethod: { type: String, required: true },
    phonesContact: { type: Object, required: true },
    emailsContact: { type: Object, required: true },
    uuid: { type: String, required: true },
    languages: { type: Array, required: true },
    pinContentHtml: { type: Object, required: true },
    customApi: { type: Boolean, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();

    interface pinAuthentication {
      languages: {
        en: boolean;
        es: boolean;
        ru: boolean;
      };
      pin: string;
      languageData: string;
      identification: string;
      phone: any;
      email: any;
      dni: string
      birthDate: string | null
      dropDownListHeader: any,
    }

    const data = reactive<pinAuthentication>({
      languages: {
        en: false,
        es: false,
        ru: false,
      },
      pin: "",
      languageData: "",
      identification: "",
      phone: null,
      email: null,
      dni: '',
      birthDate: null,
      dropDownListHeader: {}
    });

    const fallbackContactInfo = computed(() => {
      return store.getters["security/getFallbackContactInfo"];
    });

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    const isValidDocument = computed(() => {
      return checkDocument(data.identification);
    });

    const isCustomApi = computed(() => {
      return props.customApi === true ? checkDocument(data.identification) : "";
    });

    const isPhone = computed(() => {
      return props.sendMethod === "twilio_sms" || props.sendMethod === "sms";
    });
    const isEmail = computed(() => {
      return props.sendMethod === "mail";
    });

    const hasEmailOrPhone = computed(() => {
      if (data.phone === null && data.email === null) return true;
      return false;
    });

    const hasDniOrBirthday = computed(() => {
      if (data.dni.length === 0 || data.birthDate === null) return true;
      return false;
    });

    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    function changePhone(value: string) {
      data.phone = value;
    }

    function changeEmail(value: string) {
      data.email = value;
    }

    const pinPageCustomization = computed(() => {
      return store.getters["modules/getPinPageCustomization"];
    });

    function changeLocale(locale: any) {
      store.commit("user/setLanguage", locale);
      i18n.global.locale = locale;
    }

    function checkDocument(text: string) {
      return Utils.validateIdentification(text);
    }

    function getAuthentication() {
      emit("successfulAuthentication");
    }

    function checkPin() {
      const params = {
        uuid: props.uuid,
        pin: data.pin,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit("security/setToken", callbackSuccessData.data.token);
          emit("successfulAuthentication");
        },
        callbackError: (callbackErrorData: any) => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "",
            message: callbackErrorData.data,
            color: "success",
          });
        },
      };
      store.dispatch("modules/postCheckPin", params);
    }

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    function postSendPin() {
      const params = {
        uuid: props.uuid,
        uniqueId: data.identification,
        phone: data.phone,
        mail: data.email,
        callbackSuccess: () => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "alert-triangle",
            message: "pinUnique.toastMessage",
            color: "success",
          });
        },
        callbackError: (callbackErrorData: any) => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "",
            message: "pinUnique.errorId",
            color: "danger",
          });
        },
      };
      store.dispatch("modules/postSendPin", params);
    }

    function postSendDniBirthday() {
      const params = {
        uuid: props.uuid,
        dni: data.dni, 
        birthDate: data.birthDate,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit("security/setToken", callbackSuccessData.data.token);
          emit("successfulAuthentication");
        },
        callbackError: (callbackErrorData: any) => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "",
            message: callbackErrorData.data.message,
            color: "danger",
          });
        },
      };
      store.dispatch("modules/postSendDniBirthday", params);
    }

    props.languages.forEach((lang: any) => {
      data.languages = {
        ...data.languages,
        [lang]: lang,
      };
    });

    function getGeneralModule() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          callbackSuccessData.data.extraLanguage.forEach((lang: any) => {
            data.languages = {
              ...data.languages,
              [lang]: lang,
            };
          });
          store.commit("user/setLanguage", callbackSuccessData.data.defaultLanguage);
          data.languageData = callbackSuccessData.data.defaultLanguage
          data.dropDownListHeader =  callbackSuccessData.data.dropDownListHeader.text
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getGeneralModule", params);
    }

    getGeneralModule();
    changeLocale(language.value);

    watch(language, (lang) => {
      changeLocale(lang);
    });

    const handleInput = (event: any) => {
      const code = event.target.value;
      data.pin = code;
    };

    return {
      ...toRefs(data),
      fallbackContactInfo,
      changeLocale,
      checkPin,
      getAuthentication,
      language,
      postSendPin,
      getGeneralModule,
      isMobile,
      Utils,
      checkDocument,
      isValidDocument,
      changePhone,
      changeEmail,
      isPhone,
      isEmail,
      hasEmailOrPhone,
      isCustomApi,
      pinPageCustomization,
      handleInput,
      modules,
      postSendDniBirthday,
      hasDniOrBirthday
    };
  },
});
