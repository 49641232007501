
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpInputRange from "@/components/ui/SpInputRange.vue";
import SpSquareButton from "@/components/ui/SpSquareButton.vue";
import { useStore } from "vuex";
import * as Utils from "@/api/Utils";
import SpTitle from "@/components/ui/SpTitle.vue";
import SpCalendar from "@/components/ui/SpCalendar.vue";
import moment from "moment/moment";
import SpSelect from "@/components/ui/SpSelect.vue";
import SpModal from "@/components/ui/SpModal.vue";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpTable from "@/components/ui/SpTable.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";

export default defineComponent({
  name: "PaymentPlanProposal",
  components: {
    SpIcon,
    SpSquareButton,
    SpButton,
    SpInputRange,
    SpTitle,
    SpCalendar,
    SpSelect,
    SpModal,
    SpButtonIcon,
    SpTable,
  },
  props: {
    uuid: { type: String, required: true, default: "" },
    title: { type: Object, required: true },
    subtitle: { type: Object, required: true },
    button: { type: Object, required: true },
    buttonTextColor: { type: String, required: true, default: "" },
    buttonBackgroundColor: { type: String, required: true, default: "" },
    moduleHeader: { type: String, required: true, default: "" },
    options: { type: Array, required: true, default: [] },
    minMonths: { type: Number, required: true, default: 2 },
    maxMonths: { type: Number, required: true, default: 12 },
    minFee: { type: Number, required: true, default: 1 },
    maxFee: { type: Number, required: true, default: 120 },
    minPartialAmount: { type: Number, required: true, default: 1 },
    currency: { type: String, required: true, default: "EUR" },
    allowSelection: { type: Boolean, required: true, default: true },
    removalsLimitDate: { type: String, required: true, default: "" },
    removalsPercentage: { type: Number, required: true, default: 0 },
    paymentPlanTableHtml: { type: Object, required: true },
    planLimitDays: { type: Number, required: true, default: 1 },
    proportionalSlider: { type: Boolean, required: true, default: false },
    isTemplate: { type: Boolean, required: true, default: false },
  },
  emits: ["selectedPayment", "goToPaymentModule"],
  setup(props, { emit }) {
    const store = useStore();
    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    const today = moment().format("YYYY-MM-DD");

    interface paymentPlanProposal {
      initialLoading: {
        postGetTotalToPay: boolean;
        getPaymentPlanData: boolean;
        getFilesToAddToPayment: boolean;
      };
      loadingPaymentPlan: boolean;
      activePayment: string;
      activePaymentSingle: boolean;
      quantity: number;
      quantityPartial: string;
      months: number;
      loading: boolean;
      paymentPlans: Array<any>;
      headers: Array<any>;
      colorToUse: string;
      paymentPlanData: any;
      preferredDueDay: string;
      disabledForm: boolean;
      debtAmountDato: number;
      seeTextQuita: boolean;
      seeModule: boolean;
      dateForCall: string;
      isModalPaymentActive: boolean;
      testItems: Array<any>;
      paymentOriginOptions: Array<any>;
      selectEnable: boolean;
      tableItems: Array<any>;
      page: number;
      maxNumberOfPages: number;
      paymentPlansSelected: any;
      totalToPay: any;
      minValue: number;
      minMonthsFinal: number;
      maxMonthsFinal: number;
      minAmountFinal: number;
      maxAmountFinal: number;
      selectedOption: string | null;
      valoresPermitidos: Array<any>;
      valueChangeProportional: boolean;
    }
    const data = reactive<paymentPlanProposal>({
      initialLoading: {
        postGetTotalToPay: true,
        getPaymentPlanData: true,
        getFilesToAddToPayment: true,
      },
      loadingPaymentPlan: true,
      activePayment: "single",
      activePaymentSingle: false,
      quantity: 0,
      quantityPartial: "0",
      months: 1,
      loading: false,
      isModalPaymentActive: false,
      paymentPlans: [],
      headers: [
        { label: "Amount" },
        { label: "Months" },
        { label: "Frequency" },
        { label: "Table" },
      ],
      tableItems: [],
      testItems: [
        {
          date: "20/4/23",
          payment: 1,
          amount: "100€",
        },
        {
          date: "20/4/23",
          payment: 2,
          amount: "100€",
        },
        {
          date: "20/4/23",
          payment: 3,
          amount: "100€",
        },
      ],
      colorToUse: store.getters["user/getPrimaryColor"],
      paymentPlanData: store.getters["modules/getPaymentPlanDataModule"],
      preferredDueDay: moment().format("DD/MM/YYYY"),
      disabledForm: false,
      debtAmountDato: 0,
      seeTextQuita: false,
      seeModule: false,
      dateForCall: today,
      paymentOriginOptions: [
        {
          label: "Nómina",
          value: "Nomina",
        },
        {
          label: "Pensión",
          value: "Pension",
        },
        {
          label: "Subsidio de desempleo ",
          value: "Subsidio",
        },
        {
          label: "Subsidio de desempleo ",
          value: "Subsidio",
        },
        {
          label: "Subsidio de desempleo ",
          value: "Subsidio",
        },
      ],
      selectEnable:
        store.getters["modules/getPaymentPlanDataModule"].confirmationPreview
          .enableOrigin,
      page: 1,
      maxNumberOfPages: 1,
      paymentPlansSelected: null,
      totalToPay: "",
      minValue: 50,
      minMonthsFinal: props.minMonths,
      maxMonthsFinal: props.maxMonths,
      minAmountFinal: 0,
      maxAmountFinal: 0,
      selectedOption: null,
      valoresPermitidos: [],
      valueChangeProportional: false,
    });

    const subtitleComputed = computed(() => {
      let result: string = props.subtitle[language.value].slice();
      for (let fpt in store.getters["modules/getSelectedFileData"].fpt) {
        result = result.replaceAll(
          "{" + fpt + "}",
          store.getters["modules/getSelectedFileData"].fpt[fpt]
        );
      }

      return result;
    });
    const isTemplate = computed(() => {
      return store.getters["modules/getIsTemplate"];
    });

    function months() {
      if (Math.round(getAmountToPay.value / props.maxMonths) < props.minFee) {
        data.minAmountFinal = props.minFee;
        data.maxMonthsFinal = Math.floor(
          getAmountToPay.value / data.minAmountFinal
        );
        data.minMonthsFinal = props.minMonths;
        data.minMonthsFinal > data.maxMonthsFinal
          ? (data.maxMonthsFinal = data.minMonthsFinal)
          : "";

        data.maxAmountFinal = getAmountToPay.value / data.minMonthsFinal;
        data.minAmountFinal =
          Math.ceil((getAmountToPay.value / data.maxMonthsFinal) * 100) / 100;

        for (let i = 2; i <= data.maxMonthsFinal; i++) {
          data.valoresPermitidos.push(getAmountToPay.value / i);
        }
      } else {
        data.maxAmountFinal = getAmountToPay.value / props.minMonths;
        data.minAmountFinal =
          Math.ceil((getAmountToPay.value / props.maxMonths) * 100) / 100;
        data.minMonthsFinal = props.minMonths;
        data.maxMonthsFinal = props.maxMonths;

        for (let i = 2; i <= data.maxMonthsFinal; i++) {
          data.valoresPermitidos.push(getAmountToPay.value / i);
        }
      }
    }

    const primaryColor = computed(() => {
      return store.getters["user/getPrimaryColor"];
    });

    function changeSelectedOption(option: any) {
      data.selectedOption = option;
    }

    function selectDeadLine(deadLine: any) {
      let total: any = 0;
      data.paymentPlans.forEach((item: any) => {
        if (item["@id"] === deadLine["@id"]) {
          item.check && item.paymentPlanDeadline
            ? (total = item.paymentPlanDeadline.amount)
            : "";
        } else {
          item.check = false;
        }
      });
      store.commit("modules/setTotalToPay", total);
    }

    function preferredDueDayFunction(date: any) {
      data.preferredDueDay = date;
    }

    const debtAmount = computed<number>(() => {
      return data.debtAmountDato;
    });

    watch(debtAmount, () => {
      data.quantity = store.getters["modules/getAmountWithRemoval"];
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const seeButtons = computed(() => {
      if (isTemplate.value) return true;

      let seeBoolean = true;
      data.paymentPlans.forEach((element: any) => {
        element.status === "approved" && element.type === "monthly"
          ? (seeBoolean = false)
          : "";
      });
      return seeBoolean;
    });

    const seePartial = computed(() => {
      let seeBoolean = false;
      data.paymentPlans.forEach((element: any) => {
        element.status === "approved" && element.type === "monthly"
          ? (seeBoolean = true)
          : "";
      });
      return seeBoolean;
    });

    const getRemovalTitle = computed(() => {
      let title = data.paymentPlanData.removals.title.text[store.getters["user/getLanguage"]];
      for(let fpt in store.getters['modules/getSelectedFileData'].fpt) {
        title = title.replaceAll('{' + fpt + '}', store.getters['modules/getSelectedFileData'].fpt[fpt])
      }
      return replaceVariables(title);
    });

    const getRemovalSubtitle = computed(() => {
      let subtitle = data.paymentPlanData.removals.subtitle.text[store.getters["user/getLanguage"]];
        for(let fpt in store.getters['modules/getSelectedFileData'].fpt) {
        subtitle = subtitle.replaceAll('{' + fpt + '}', store.getters['modules/getSelectedFileData'].fpt[fpt])
      }
      return replaceVariables(subtitle);
    });

    const getTableTotals = computed(() => {
      const title =
        props.paymentPlanTableHtml.totalsText[
          store.getters["user/getLanguage"]
        ];
      const color = props.paymentPlanTableHtml.totalsColor;
      const textColor = props.paymentPlanTableHtml.totalsTextColor;

      return { title, color, textColor };
    });

    const getTableHeader = computed(() => {
      const amount =
        props.paymentPlanTableHtml.headerText.header1[
          store.getters["user/getLanguage"]
        ];
      const date =
        props.paymentPlanTableHtml.headerText.header2[
          store.getters["user/getLanguage"]
        ];
      const monthly =
        props.paymentPlanTableHtml.headerText.header3[
          store.getters["user/getLanguage"]
        ];
      const status =
        props.paymentPlanTableHtml.headerText.header4[
          store.getters["user/getLanguage"]
        ];
      const color = props.paymentPlanTableHtml.headerColor;
      const textColor = props.paymentPlanTableHtml.headerTextColor;

      return { amount, date, monthly, status, color, textColor };
    });

    const paymentPlanTableHtml = computed(() => {
      const title =
        props.paymentPlanTableHtml.titleText[store.getters["user/getLanguage"]];
      const color = props.paymentPlanTableHtml.titleColor;

      return { title, color };
    });

    const getTableSubTitle = computed(() => {
      const title =
        props.paymentPlanTableHtml.subtitleText[
          store.getters["user/getLanguage"]
        ];

      const color = props.paymentPlanTableHtml.subtitleColor;

      return { title, color };
    });

    const getAmountToPay = computed(() => {
      let dateToCompare = moment(data.paymentPlanData.removalsLimitDate);
      if (dateToCompare.diff(moment(), "days") > 0) {
        data.seeTextQuita = true;
        return debtAmount.value;
      } else {
        data.seeTextQuita = false;
        return debtAmount.value;
      }
    });

    function changePaymentMonths(months: number) {
      data.months = Number(months);
      data.quantity =
        data.months === 0
          ? getAmountToPay.value
          : Math.ceil((getAmountToPay.value / data.months) * 100) / 100;
    }

    function changePaymentAmount(amount: number) {
      if (props.proportionalSlider) {
        const valorActual = parseFloat(`${amount}`);
        const valorCercano = data.valoresPermitidos.reduce((a, b) => {
          return Math.abs(b - valorActual) < Math.abs(a - valorActual) ? b : a;
        });
        data.quantity = valorCercano;
        data.valueChangeProportional = !data.valueChangeProportional;
        data.months = Math.ceil(getAmountToPay.value / data.quantity);
      } else {
        data.quantity = parseFloat(`${amount}`);
        data.months = Math.ceil(getAmountToPay.value / amount);
      }
    }

    function replaceVariables(text: string) {
      const fileData = store.getters["modules/getSelectedFileData"];
      return text
        .replaceAll(
          "{removalsPercentage}",
          data.paymentPlanData.removalsPercentage
        )
        .replaceAll(
          "{removalsLimitDate}",
          Utils.formatDate(data.paymentPlanData.removalsLimitDate)
        )
        .replaceAll(
          "{pendingDebtWithRemoval}",
          Utils.formatNumberWithCurrency(
            fileData.pendingDebtWithRemoval,
            props.currency
          )
        )
        .replaceAll(
          "{removalAmount}",
          Utils.formatNumberWithCurrency(fileData.removalAmount, props.currency)
        )
        .replaceAll(
          "{totalDebt}",
          Utils.formatNumberWithCurrency(fileData.totalDebt, props.currency)
        );
    }

    function changeSelectedPaymentPlanProposal(status: any) {
      data.activePayment = status.id;
      data.activePayment === "custom"
        ? (data.months = modules.value
            ? modules.value.paymentPlan.minMonths
            : 2)
        : (data.months = 1);
      data.quantity =
        data.months === 0
          ? getAmountToPay.value
          : getAmountToPay.value / data.months;
    }

    function chageDateforCall(date: any) {
      data.dateForCall = date;
    }

    function setIsModalPaymentActive() {
      data.isModalPaymentActive = !data.isModalPaymentActive;
    }

    function postCreatePaymentPlanData() {
      let type = "";
      if (data.activePayment === "custom") {
        type = "custom";
      }
      if (data.activePayment === "partial") {
        type = "partial";
      }
      if (data.activePayment === "single") {
        type = "unified";
      }

      let frequency = "";
      if (data.activePayment === "custom") {
        frequency = "monthly";
      }
      if (data.activePayment === "partial") {
        frequency = "partial";
      }
      if (data.activePayment === "single") {
        frequency = "single";
      }

      if (
        data.quantity > 0 ||
        (data.activePayment === "partial" && parseInt(data.quantityPartial) > 0)
      ) {
        data.loading = true;
        const params = {
          uuid: props.uuid,
          customPaymentAmount:
            data.activePayment === "partial"
              ? parseInt(data.quantityPartial)
              : data.quantity,
          customPaymentPeriodMonths: data.months,
          preferredDueDay: data.dateForCall,
          type: type,
          paymentFrequency: frequency,
          files: store.getters["modules/getFilesInfo"].map((file: any) => {
            return file.fileUuid;
          }),
          origin: data.selectedOption,
          totalDebt: getAmountToPay.value,
          callbackSuccess: () => {
            getPaymentPlanData();
            emit("goToPaymentModule");
            data.loading = false;
          },
          callbackError: () => {
            data.loading = false;
          },
        };
        store.dispatch("modules/postCreatePaymentPlanData", params);
      } else {
        store.commit("user/pushNotification", {
          id: Date.now(),
          icon: "alert-triangle",
          message: "notifications.payError",
          color: "warning",
        });
      }
    }

    function getPaymentPlanData() {
      data.initialLoading.getPaymentPlanData = true;
      data.loadingPaymentPlan = true;
      const params = {
        uuid: props.uuid,
        fileID: store.getters["modules/getFileUuid"],
        callbackSuccess: (callbackSuccessData: any) => {
          if (callbackSuccessData.data.length > 0)
            data.paymentPlans = callbackSuccessData.data;
          data.paymentPlans[data.paymentPlans.length - 1]
            ? (data.paymentPlans[data.paymentPlans.length - 1].check = true)
            : "";
          selectDeadLine(data.paymentPlans[data.paymentPlans.length - 1]);
          data.paymentPlans.forEach((item: any) => {
            if (item.status === "approved")
              store.commit(
                "user/setPaymentAmount",
                item.paymentPlanDeadline.amount
              );
          });
          data.initialLoading.getPaymentPlanData = false;
          data.loadingPaymentPlan = false;
        },
        callbackError: () => {
          data.loadingPaymentPlan = false;
          data.initialLoading.getPaymentPlanData = false;
        },
      };
      store.dispatch("modules/getPaymentPlanData", params);
    }

    function postGetTotalToPay() {
      data.initialLoading.postGetTotalToPay = true;
      const params = {
        uuid: props.uuid,
        fileID: store.getters["modules/getFileUuid"],
        callbackSuccess: (callbackSuccessData: any) => {
          if (store.getters["modules/getIsTemplate"]) {
            data.debtAmountDato =
              store.getters["modules/getDefaultFileInfo"].pendingDebt;
          } else {
            data.debtAmountDato = callbackSuccessData.data.pendingTotalDebt;
          }
          data.initialLoading.postGetTotalToPay = false;
          getFilesToAddToPayment();
        },
        callbackError: () => {
          data.initialLoading.postGetTotalToPay = false;
        },
      };
      store.dispatch("modules/postGetTotalToPay", params);
    }

    function getFilesToAddToPayment() {
      data.initialLoading.getFilesToAddToPayment = true;
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          if (
            callbackSuccessData.data.availableFilesToAddToAPaymentPlan.length >
            0
          ) {
            callbackSuccessData.data.availableFilesToAddToAPaymentPlan.forEach(
              (item: any) => {
                item.fileUuid === store.getters["modules/getFileUuid"]
                  ? (data.seeModule = true)
                  : "";
              }
            );
          }
          months();
          data.initialLoading.getFilesToAddToPayment = false;
        },
        callbackError: () => {
          data.initialLoading.getFilesToAddToPayment = false;
        },
      };
      store.dispatch("modules/getFilesToAddToPayment", params);
    }

    postGetTotalToPay();
    getPaymentPlanData();

    watch(
      () => store.getters["modules/getFileUuid"],
      () => {
        getPaymentPlanData();
        postGetTotalToPay();
        months();
      }
    );

    const customApi = computed(() => {
      return store.getters["user/getCustomApi"];
    });

    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    const tableConfirmHeaders = computed(() => {
      const translatedHeaders: Array<any> = [];
      const headers: any = Object.values(
        data.paymentPlanData.confirmationPreview.tableHeaderContent
      );
      headers.forEach((header: any) => {
        translatedHeaders.push(header[language.value]);
      });

      return translatedHeaders.map((header: string) => ({
        label: header,
        align: "left",
      }));
    });
    const itemsPerPage = computed(() => {
      return isMobile.value ? 1 : 6;
    });

    const selectConfirmOptions = computed(() => {
      const translatedSelect: Array<any> = [];
      const selects: any = Object.values(
        data.paymentPlanData.confirmationPreview.originOptions
      );
      selects.forEach((selects: any) => {
        translatedSelect.push(selects[language.value]);
      });

      return translatedSelect.map((selects: string) => ({
        label: selects,
        value: selects,
      }));
    });

    function activeConfirmPaymentPlan() {
      postTableData();
      setIsModalPaymentActive();
    }
    const card = computed(() => {
      return store.getters["modules/getCard"];
    });

    function sendConfirm() {
      postCreatePaymentPlanData();
      setIsModalPaymentActive();
    }

    function onClickHandler() {
      if (data.paymentPlanData.confirmationPreview.enablePaymentConfirm) {
        data.activePayment !== "custom"
          ? postCreatePaymentPlanData()
          : activeConfirmPaymentPlan();
      } else if (data.activePayment === "partial") {
        Number(data.quantityPartial) >= props.minPartialAmount
          ? postCreatePaymentPlanData()
          : store.commit("user/pushNotification", {
              id: Date.now(),
              icon: "alert.triangle",
              message: "general.amountError",
              color: "warning",
            });
      } else {
        postCreatePaymentPlanData();
      }
    }

    function postTableData() {
      const params = {
        preferred_day: data.dateForCall,
        monthly_cost: data.quantity,
        months: data.months,
        itemsPerPage: itemsPerPage.value,
        page: data.page,
        totalDebt: getAmountToPay.value,
        callbackSuccess: (callbackSuccessData: any) => {
          data.tableItems = isMobile.value
            ? callbackSuccessData.data.data.map((item: any) => {
                const [date, payment, quantity] = tableConfirmHeaders.value.map(
                  (header: any) => header.label
                );
                const { amount, deadlineDate, number } = item;
                return {
                  [date]: deadlineDate,
                  [payment]: number,
                  [quantity]: Utils.formatNumberWithCurrency(amount),
                };
              })
            : callbackSuccessData.data.data;
          data.maxNumberOfPages = Math.ceil(
            callbackSuccessData.data.totalItems / itemsPerPage.value
          );
        },
        callbackError: () => {},
      };
      store.dispatch("modules/postPaymentPlanTable", params);
    }

    function getPage(type: any) {
      if (type === "first") {
        data.page = 1;
        postTableData();
      } else if (type === "last") {
        data.page = data.maxNumberOfPages;
        postTableData();
      } else if (type === "next" && data.page < data.maxNumberOfPages) {
        data.page++;
        postTableData();
      } else if (type === "previous" && data.page > 1) {
        data.page--;
        postTableData();
      }
    }

    function selectPlan(plan: any, totalToPay: any) {
      data.paymentPlansSelected = plan;
      data.totalToPay = totalToPay;
    }

    function minValue() {
      return parseInt(data.quantityPartial) === data.minValue;
    }

    function isNumber(evt: any) {
      evt = evt ? evt : window.event;
      const regex = /[0-9]|\+|\(|\)/;
      if (!regex.test(evt.key)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

    months();

    return {
      ...toRefs(data),
      changePaymentMonths,
      changeSelectedPaymentPlanProposal,
      postCreatePaymentPlanData,
      getFilesToAddToPayment,
      getRemovalTitle,
      getRemovalSubtitle,
      getAmountToPay,
      debtAmount,
      isMobile,
      Utils,
      seeButtons,
      language,
      customApi,
      chageDateforCall,
      preferredDueDayFunction,
      setIsModalPaymentActive,
      tableConfirmHeaders,
      selectConfirmOptions,
      onClickHandler,
      activeConfirmPaymentPlan,
      getPage,
      itemsPerPage,
      postTableData,
      sendConfirm,
      isNumber,
      selectPlan,
      // getTabletitle,
      getTableSubTitle,
      getTableHeader,
      getTableTotals,
      selectDeadLine,
      minValue,
      card,
      primaryColor,
      changeSelectedOption,
      changePaymentAmount,
      modules,
      seePartial,
      subtitleComputed,
      isTemplate
    };
  },
});
