
import {computed, defineComponent, reactive, toRefs, watch} from 'vue'
import * as Utils from '@/api/Utils'
import {useStore} from 'vuex'

const __default__ = defineComponent({
  name: "SpInputRange",
  props: {
    min: { type: Number, required: true, default: 1 },
    max: { type: Number, required: true, default: 100 },
    value: { type: Number, required: true, default: 0 },
    showValue: { type: Boolean, required: false },
    showUnit: { type: Boolean, required: false, default: false },
    unitToShow: { type: String, required: false, default: '' },
    disabled: { type: Boolean, required: false, default: false },
    currency: { type: String, required: false, default: 'EUR' },
    quantity: { type: Boolean, required: false, default: false },
    stepFloat: { type: Boolean, required: false, default: false },
    valueChangeProportional: { type: Boolean, required: false, default: false },
  },
  emits: ['change'],
  setup (props, { emit }) {
    const store = useStore()

    interface spInputRange {
      inputMin: number,
      inputMax: number,
      inputValue: number
      inputValueToShow: number
      colorToUse: string
    }

    const data = reactive<spInputRange>({
      inputMin: 0,
      inputMax: 100,
      inputValue: 20,
      inputValueToShow: 20,
      colorToUse: store.getters['user/getPrimaryColor']
    })

    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    data.inputValue = parseFloat(`${props.value}`)
    data.inputMin = parseFloat(`${props.min}`)
    data.inputMax = parseFloat(`${props.max}`)

    watch(() => props.value, (currentValue, oldValue) => {
      data.inputValue = parseFloat(`${props.value}`)
      data.inputMin = parseFloat(`${props.min}`)
      data.inputMax = parseFloat(`${props.max}`)
    })

    watch(() => props.valueChangeProportional, (currentValue, oldValue) => {
      data.inputValue = parseFloat(`${props.value}`)
      data.inputMin = parseFloat(`${props.min}`)
      data.inputMax = parseFloat(`${props.max}`)
    })

    watch(() => props.min, (currentValue, oldValue) => {
      data.inputValue = parseFloat(`${props.value}`)
      data.inputMin = parseFloat(`${props.min}`)
      data.inputMax = parseFloat(`${props.max}`)
    })

    watch(() => props.max, (currentValue, oldValue) => {
      data.inputValue = parseFloat(`${props.value}`)
      data.inputMin = parseFloat(`${props.min}`)
      data.inputMax = parseFloat(`${props.max}`)
    })

    function change () {
      emit('change', props.stepFloat ? Math.ceil(data.inputValue* 100) / 100 : data.inputValue)
    }

    const inputValuePercent = computed(() => {
      let auxMax: number = data.inputMax
      if (data.inputMin !== 0) auxMax = data.inputMax - data.inputMin
      let result: number = ((data.inputValue) * 100)/auxMax
      if (data.inputMin !== 0) result -= 100
      return result
    })

    const inputValueToShow = computed(() => {
      return props.quantity ? Utils.formatNumberWithCurrency(data.inputValue, ) : data.inputValue
    })

    return {
      ...toRefs(data),
      change,
      inputValuePercent,
      inputValueToShow,
      Utils,
      modules
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "18789afe": (_ctx.colorToUse)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__