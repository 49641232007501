<template>
  <div class="mt-4 p-4">
    <div class="rounded bg-gray-100 p-4">
      <p class="font-semibold">Transfer</p>
      <form
        name="from"
        action="https://sis-t.redsys.es:25443/sis/realizarPago"
        method="POST"
      >
        <input
          type="hidden"
          name="Ds_SignatureVersion"
          :value="Ds_SignatureVersion"
        />
        <input
          type="hidden"
          name="Ds_MerchantParameters"
          :value="Ds_MerchantParameters"
        />
        <input type="hidden" name="Ds_Signature" :value="Ds_Signature" />
        <input
            id="btn-pay-transfer"
          type="submit"
          :value="text"
          class="rounded btn-primary md cursor-pointer"
          :style="'background: ' + primaryColor + '; border-color: ' + primaryColor + ';'"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, inject, toRefs } from "vue";
import { useStore } from "vuex";
import SpButton from "@/components/ui/SpButton.vue";
import SpModal from "@/components/ui/SpModal.vue";
import SpIcon from "@/components/ui/SpIcon.vue";
import * as Utils from "@/api/Utils";

export default defineComponent({
  name: "Transfer",
  components: { SpIcon, SpModal, SpButton},
  props: {
    uuid: { type: String, required: true, default: "" },
    debtAmount: { type: Number, required: true, default: 0 },
    text: { type: String, required: true, },
  },
  setup(props, { emit }) {
    const cryoptojs = inject("cryptojs");
    const store = useStore()

    const primaryColor = computed(() => {
      return store.getters['user/getPrimaryColor']
    })

    const data = reactive({
      Ds_SignatureVersion: "HMAC_SHA256_V1",
      Ds_MerchantParameters: "",
      Ds_Signature: "",
      transaction: {
        quantity: 10,
        tpvId: "",
        pageType: "c",
      },
      amount: props.debtAmount
    });

    function getRandomNumber() {
      const min = 1446068591;
      const max = 9446068591;
      return (Math.floor(Math.random() * (max - min + 1) + min)).toString();
    }

    // Redsys
    const key = "sq7HjrUOBfKmC576ILgskD5srU870gJ7";

    let total = ((data.amount.toFixed(2)).toString()).replace('.','');
    let paymentId = "1446068595";

    let tpvInfo = {
      fucCode: "347234460",
      currency: "978",
      transaction_type: "0",
      terminal: "0001",
    };

    const mParams = {
      DS_MERCHANT_ORDER: getRandomNumber(),
      DS_MERCHANT_MERCHANTCODE: tpvInfo.fucCode,
      DS_MERCHANT_TERMINAL: tpvInfo.terminal,
      DS_MERCHANT_CURRENCY: tpvInfo.currency,
      DS_MERCHANT_TRANSACTIONTYPE: tpvInfo.transaction_type,
      DS_MERCHANT_AMOUNT: '100',
      DS_MERCHANT_MERCHANTURL: "http://www.prueba.com/urlNotificacion.php",
      DS_MERCHANT_URLKO: process.env.VUE_APP_REDSYS_URL + store.getters["modules/getTemplateId"] + "/" + store.getters["modules/getElementId"] + "/" + props.uuid + '/KO',
      DS_MERCHANT_URLOK: process.env.VUE_APP_REDSYS_URL + store.getters["modules/getTemplateId"] + "/" + store.getters["modules/getElementId"] + "/" + props.uuid + '/OK',
      DS_MERCHANT_PAYMETHODS: "R",
    };

    // BIZUM	z
    // PAYPAL	p
    // Transferencia	R
    // Masterpass	N
    // Con Tarjeta	C

    let merchant = cryoptojs.enc.Utf8.parse(JSON.stringify(mParams));
    data.Ds_MerchantParameters = merchant.toString(cryoptojs.enc.Base64);
    let keyBase64 = cryoptojs.enc.Base64.parse(key);

    let iv = cryoptojs.enc.Hex.parse("0000000000000000");
    let cipher = cryoptojs.TripleDES.encrypt(
      mParams.DS_MERCHANT_ORDER,
      keyBase64,
      {
        iv: iv,
        mode: cryoptojs.mode.CBC,
        padding: cryoptojs.pad.ZeroPadding, // Redsys dont like padding
      }
    );
    let signature = cryoptojs.HmacSHA256(
      data.Ds_MerchantParameters,
      cipher.ciphertext
    );

    data.Ds_Signature = signature.toString(cryoptojs.enc.Base64);

    return {
      ...toRefs(data),
      cryoptojs,
      Utils,
      primaryColor
    };
  },
});
</script>

<style>
</style>    