import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "flex flex-col justify-center items-center" }
const _hoisted_3 = {
  key: 0,
  class: "font-semibold text-xl"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  class: "text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeIsActive && _ctx.changeIsActive(...args))),
    id: 'btn-payment-' + _ctx.id,
    class: _normalizeClass(["w-full", _ctx.active ? _ctx.spSquareButtonClass + '-active' : _ctx.spSquareButtonClass]),
    style: _normalizeStyle(_ctx.active ? _ctx.buttonStyleSelected : _ctx.buttonStyle)
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.img.length === 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.img,
            alt: ""
          }, null, 8, _hoisted_4)),
      (_ctx.description.length > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true)
    ])
  ], 14, _hoisted_1))
}