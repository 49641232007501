import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2742cf68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full flex justify-between items-center" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "w-full flex justify-end" }
const _hoisted_6 = { class: "flex flex-col items-end w-1/2 pl-2 pr-2" }
const _hoisted_7 = { class: "text-xs opacity-75" }
const _hoisted_8 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_9 = {
  key: 1,
  class: "text-sm"
}
const _hoisted_10 = { class: "flex flex-col items-end w-1/2 pl-2 pr-2" }
const _hoisted_11 = { class: "text-xs opacity-75" }
const _hoisted_12 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_13 = {
  key: 1,
  class: "text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectFile && _ctx.selectFile(...args))),
    class: _normalizeClass(_ctx.isActive ? 'file-selector-card-active':'file-selector-card'),
    style: _normalizeStyle(_ctx.isActive ? 'background :' + _ctx.primaryColor + ';' : '')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        style: {},
        innerHTML: _ctx.title
      }, null, 8, _hoisted_2),
      _createVNode(_component_sp_icon, {
        model: _ctx.isActive ? 'check-circle' : 'circle'
      }, null, 8, ["model"])
    ]),
    (_ctx.debtOwner !== '-')
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "text-xs ellipse-two-lines",
          innerHTML: _ctx.subtitle
        }, null, 8, _hoisted_3))
      : (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: "text-xs ellipse-two-lines",
          innerHTML: _ctx.subtitle
        }, null, 8, _hoisted_4)),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.debtSelector.card.columns.pendingDebt.text[_ctx.language]), 1),
        (_ctx.pendingDebt !== '-')
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.Utils.formatNumberWithCurrency(_ctx.pendingDebt, _ctx.currency)), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_9, "-"))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.debtSelector.card.columns.totalDebt.text[_ctx.language]), 1),
        (_ctx.totalDebt !== '-')
          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.Utils.formatNumberWithCurrency(_ctx.totalDebt, _ctx.currency)), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_13, "-"))
      ])
    ])
  ], 6))
}