
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import SpTimerPickerAx from "@/components/axactorModulesMobile/SpTimerPickerAx.vue";
import SpCalendarAx from "@/components/axactorModulesMobile/SpCalendarAx.vue";
import dialphone from "@/assets/json/dial_phone_.json";
import SpSelect from "@/components/ui/SpSelect.vue";
import moment from "moment/moment";
import router from "@/router";

export default {
  name: "CallUs",
  components: {
    SpIcon,
    SpButtonIcon,
    SpSelect,
    SpButton,
    SpTimerPickerAx,
    SpCalendarAx,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();
    const today = moment().format("YYYY-MM-DD");

    interface CallUs {
      loading: boolean;
      languages: Array<any>;
      seeInterveners: boolean;
      prefixes: Array<any>;
      prefix: any | null;
      seeTime: boolean;
      time: string;
      timeForProp: number;
      preferredDueDay: string;
      dateForCall: string;
      seeCalendar: boolean;
      dateForShow: string;
      phoneCallMeNow: string;
      phone: any,
      email: string;
    }

    const data = reactive<CallUs>({
      loading: false,
      languages: [],
      seeInterveners: false,
      prefixes: [],
      prefix: "+34",
      seeTime: false,
      time: "",
      timeForProp: 0,
      preferredDueDay: moment().format("DD/MM/YYYY"),
      dateForCall: today,
      seeCalendar: false,
      dateForShow: moment().format("DD MMMM YYYY"),
      phoneCallMeNow: '',
      phone: '',
      email: '',
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });
    const modules = computed(()=> {
        return store.getters["modules/getModules"]
      })

    function seeIntervenersFunction() {
      data.seeInterveners = !data.seeInterveners;
    }

    function preferredDueDayFunction(date: any) {
      data.preferredDueDay = date;
    }

    function chageDateforCall(date: any) {
      data.dateForCall = date;
      data.dateForShow = moment(date).format("DD MMMM YYYY");
    }

    function goTo(value: string) {
      router.push({ name: value });
    }

    function saveTime(time: any) {
      data.seeTime = false;
      time < 10 ? (data.time = "0" + time + ":00") : (data.time = time );
      data.timeForProp = time;
    }

    function callToPrefixs() {
      data.loading = true;
      const prefix = dialphone
        .filter((c: any, index: any) => {
          return dialphone.indexOf(c) === index;
        })
        .sort((a, b) => parseInt(a) - parseInt(b));
      data.prefixes = prefix.map((prefix: any) => {
        return {
          label: prefix,
          value: prefix,
        };
      });
      data.loading = false;
    }

    function changeSelectedPrefix(uuid: string | null) {
      data.prefix = uuid;
    }

    const seeIntervenersComputed = computed(() => data.seeInterveners);
    const isCustomApi = computed(()=> store.getters['modules/getCustomApi'])
    function postCallCostumenNow() {
      const params = {
        uuid: props.uuid,
        phone: data.phoneCallMeNow,
        prefix: data.prefix,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit("modules/setPhoneNumberScheduled", data.prefix + ' ' + data.phoneCallMeNow);
          goTo('CallSaved')
        },
        callbackError: (callbackErrorData: any) => {
        },
      };
      store.dispatch("modules/postCallCostumenNow", params);
    }

    function sendForm(){
      if (isCustomApi.value) {
        postSendCustomerCallPreferences()
        postSendContactEmail()
      }
      postSendContactEmail()
    }

    function postSendContactEmail() {
      let result: Array<any> = [];
        const params = {
          uuid: '/api/landing_pages/' + props.uuid,
          phone: {
            number: data.phone,
            prefix: data.prefix,
          },
          email: data.email,
          callbackSuccess: () => {
            store.commit("modules/setPhoneNumberScheduled", data.prefix + ' ' + data.phone);
            goTo('CallSaved')
          },
          callbackError: () => {
          },
        };
        store.dispatch("modules/postSendContactEmail", params);
    }
    function postSendCustomerCallPreferences() {
      let result: Array<any> = [];
        const params = {
          uuid: '/api/landing_pages/' + props.uuid,
          phone: data.phone,
          prefix: data.prefix,
          data: [{
            id: String(Date.now()),
            date:moment(data.dateForCall).format('YYYY-MM-DD'),
            sinceTheTime: data.time,
            untilTheTime: data.time,
          }],
          time: data.time,
          preferredDueDay: data.preferredDueDay,
          callbackSuccess: () => {
            store.commit("modules/setPhoneNumberScheduled", data.prefix + ' ' + data.phone);
            goTo('CallSaved')
          },
          callbackError: () => {
          },
        };
        store.dispatch("modules/postSendCustomerCallPreferences", params);
    }


    function getLandingConfiguration() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.languages =
            callbackSuccessData.data.modules.general.extraLanguages;
        },
        callbackError: (callbackErrorData: any) => {},
      };
      store.dispatch("modules/getLandingConfiguration", params);
    }

    getLandingConfiguration();
    callToPrefixs();

    return {
      ...toRefs(data),
      seeIntervenersFunction,
      seeIntervenersComputed,
      callToPrefixs,
      changeSelectedPrefix,
      saveTime,
      preferredDueDayFunction,
      chageDateforCall,
      goTo,
      postCallCostumenNow,
      postSendCustomerCallPreferences,
      postSendContactEmail,
      sendForm,
      isCustomApi ,
      language,
      modules

    };
  },
};
