import * as Modules from '@/api/Modules'

export function deleteDocument ({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.deleteDocument(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getAddressMappings({ commit }: any, props: object) {
    const { uuid, language, callbackSuccess, callbackError}: any = props
    const response = Modules.getAddressMappings(uuid, language)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getCheckPayment({ commit }: any, props: object) {
    const { uuid, deadLineID, callbackSuccess, callbackError}: any = props
    const response = Modules.getCheckPayment(uuid, deadLineID)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getGeneralModule({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.getGeneralModule(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getLandingAuth({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.getLandingAuth(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getLandingPagesCollection({ commit }: any, props: object) {
    const { callbackSuccess, callbackError}: any = props
    const response = Modules.getLandingPagesCollection()

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}


export function getLandingConfiguration({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.getLandingConfiguration(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getLandingDocuments({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.getLandingDocuments(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getLegalInformation({ commit }: any, props: object) {
    const { uuid, type, languageCode, callbackSuccess, callbackError}: any = props
    const response = Modules.getLegalInformation(uuid, type, languageCode)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getLandingPayments({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.getLandingPayments(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getPaymentPlanData({ commit }: any, props: object) {
    const { uuid, fileID, callbackSuccess, callbackError}: any = props
    const response = Modules.getPaymentPlanData(uuid, fileID)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getHistoryPayment({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.getHistoryPayment(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getFilesToAddToPayment({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.getFilesToAddToPayment(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getInfoToPayment({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.getInfoToPayment(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getJistiUrl({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.getJistiUrl(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function getDocumentTypes({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.getDocumentTypes(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postPaymentStripe({ commit }: any, props: object) {
    const { uuid, deadline, amount, file, callbackSuccess, callbackError}: any = props
    const response = Modules.postPaymentStripe(uuid, deadline, amount, file)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function patchDocumentTag({ commit }: any, props: object) {
    const { uuid, tag, callbackSuccess, callbackError}: any = props
    const response = Modules.patchDocumentTag(uuid, tag)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function patchSelectedFile({ commit }: any, props: object) {
    const { uuid, externalId, callbackSuccess, callbackError}: any = props
    const response = Modules.patchSelectedFile(uuid, externalId)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postCreateFinconectaPayment({ commit }: any, props: object) {
    const { uuid, amount, paymentMethod, callbackSuccess, callbackError}: any = props
    const response = Modules.postCreateFinconectaPayment(uuid, amount, paymentMethod)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postSendSupportContent({ commit }: any, props: object) {
    const { uuid, message, callbackSuccess, callbackError}: any = props
    const response = Modules.postSendSupportContent(uuid, message)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postSendCustomerCallPreferences({ commit }: any, props: object) {
    const { uuid, data, phone, prefix, callbackSuccess, callbackError}: any = props
    const response = Modules.postSendCustomerCallPreferences(uuid, data, phone, prefix)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postCheckPin({ commit }: any, props: object) {
    const { uuid, pin, callbackSuccess, callbackError}: any = props
    const response = Modules.postCheckPin(uuid, pin)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}
export function postCallCostumenNow ({ commit }: any, props: object) {
    const { uuid, phone, prefix, callbackSuccess, callbackError}: any = props
    const response = Modules.postCallCostumenNow(uuid, phone, prefix)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}
export function postSendRemoval({ commit }: any, props: object) {
    const { uuid, percentage, callbackSuccess, callbackError}: any = props
    const response = Modules.postSendRemoval(uuid, percentage)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postSendPin({ commit }: any, props: object) {
    const { uuid, uniqueId, phone, mail,callbackSuccess, callbackError}: any = props
    const response = Modules.postSendPin(uuid, uniqueId, phone, mail)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postSendDniBirthday({ commit }: any, props: object) {
    const { uuid, dni, birthDate,callbackSuccess, callbackError}: any = props
    const response = Modules.postSendDniBirthday(uuid, dni, birthDate)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postNoContact({ commit }: any, props: object) {
    const { uuid, callbackSuccess, callbackError}: any = props
    const response = Modules.postNoContact(uuid)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}
export function postPaymentPlanTable({ commit }: any, props: object) {
    const { preferred_day, monthly_cost, months, itemsPerPage, page, totalDebt, callbackSuccess, callbackError}: any = props
    const response = Modules.postPaymentPlanTable(preferred_day, monthly_cost, months, itemsPerPage, page, totalDebt)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postSendContactEmail({ commit }: any, props: object) {
    const { uuid,name,message, phone, email, address, callbackSuccess, callbackError}: any = props
    const response = Modules.postSendContactEmail(uuid, name, message, email, phone, address)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postSendDebtConsolidationEmail({ commit }: any, props: object) {
    const {uuid, text, callbackSuccess, callbackError}: any = props
    const response = Modules.postSendDebtConsolidationEmail(uuid, text)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}


export function postPaymentVerify({ commit }: any, props: object) {
    const {uuid, variables, callbackSuccess, callbackError}: any = props
    const response = Modules.postPaymentVerify(uuid, variables)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postCreatePayment({ commit }: any, props: object) {
    const {uuid, quantity, operationId, paymentPlanDeadLine, extra, callbackSuccess, callbackError}: any = props
    const response = Modules.postCreatePayment(uuid, quantity, operationId, paymentPlanDeadLine, extra)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postCreatePaymentPlanData({ commit }: any, props: object) {
    const {
        uuid,
        customPaymentAmount,
        customPaymentPeriodMonths,
        preferredDueDay,
        type,
        paymentFrequency,
        files,
        origin,
        totalDebt,
        callbackSuccess,
        callbackError}: any = props
    const response = Modules.postCreatePaymentPlanData(
        uuid,
        customPaymentAmount,
        customPaymentPeriodMonths,
        preferredDueDay,
        type,
        paymentFrequency,
        files,
        origin,
        totalDebt)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postUploadDocument({ commit }: any, props: object) {
    const { uuid, files, tag, callbackSuccess, callbackError}: any = props
    const response = Modules.postUploadDocument(uuid, files, tag)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postCheckoutSession({ commit }: any, props: object) {
    const { uuid, paymentPlanDeadline, amount, file, callbackSuccess, callbackError}: any = props
    const response = Modules.postCheckoutSession(uuid, paymentPlanDeadline, amount, file)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postCheckoutSessionApiMode({ commit }: any, props: object) {
    const { uuid, paymentPlanDeadline, file, amount, callbackUrl, callbackSuccess, callbackError}: any = props
    const response = Modules.postCheckoutSessionApiMode(uuid, paymentPlanDeadline, file, amount, callbackUrl)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}

export function postGetTotalToPay({ commit }: any, props: object) {
    const { uuid, fileID, callbackSuccess, callbackError}: any = props
    const response = Modules.postGetTotalToPay(uuid, fileID)

    response.then((response) => {
        if (response.success) {
            callbackSuccess(response)
            return
        } else {
            callbackError(response)
        }
    })
}